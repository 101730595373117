




















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CheckIcon, InfoIcon } from 'vue-feather-icons'
import { Quote, QuoteStatus } from '@/models/quote'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { Product } from '@/models/product'
import { quoteModule, underwritersModule } from '@/store/store-accessor'
import { Underwriter } from '@/models/underwriter'
import { Policy, PolicyStatus } from '@/models/policy'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'

@Component({
  name: 'CheckoutQuote',
  components: {
    LoadingIcon,
    InfoIcon,
    CheckIcon,
    UnderwriterLogo
  }
})
export default class CheckoutQuote extends Vue {
  @Prop() quote!: Quote;
  @Prop() policyData!: Policy;

  get queuedStatus () {
    return QuoteStatus.QUEUED
  }

  get quotedStatus () {
    return QuoteStatus.QUOTED
  }

  get quoteFailed () {
    return QuoteStatus.FAILED
  }

  get premiumPaymentStatusText () {
    return this.quote.collectPayment ? 'Paid' : 'To Be Paid'
  }

  get monthlyPayment (): number {
    return parseFloat(this.quote.policyPremium) / 12
  }

  get policyNumber (): string {
    return this.policyData.externalPolicyReference
      ? this.policyData.externalPolicyReference
      : 'Awaiting assignment'
  }

  get installmentFrequency (): string {
    const paymentOption = this.quote.paymentOptions.find(
      (option) =>
        option.installmentFrequency === this.policyData.paymentFrequency
    )
    if (paymentOption) return paymentOption.installmentFrequency
    return ''
  }

  get isBound () {
    return quoteModule.policyData?.status === PolicyStatus.Bound
  }

  get downPayment () {
    const paymentOption = this.quote.paymentOptions.find(
      (paymentOption) =>
        paymentOption.installmentFrequency ===
        quoteModule.quoteSelections[0].installmentFrequency
    )

    if (paymentOption) {
      return paymentOption?.downpayment
    }

    return 0
  }

  get products (): Product[] {
    return quoteModule.productList
  }

  get quoteProduct (): Product | undefined {
    return quoteModule.productList.find(
      (product) => product.id === this.quote.productId
    )
  }

  // error state for subclasses not selected if continue
  // do not show result s in the first load
  // checkmark for confirm?
  // text position placeholder

  get underwriter (): Underwriter | undefined {
    const id = this.quoteProduct?.underwriterId
    if (!id) return undefined
    return underwritersModule.underwriterById(id)
  }
}
