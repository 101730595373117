

























































import { brokerageModule, quoteModule } from '@/store/store-accessor'
import { CheckIcon } from 'vue-feather-icons'
import { Component, Vue } from 'vue-property-decorator'
import { getProductName } from '@/helpers/products'
import { Policy, PolicyStatus } from '@/models/policy'
import { Product, ProductType } from '@/models/product'
import { Quote } from '@/models/quote'
import { BillingType } from '@/models/underwriter'
import AgentSupport from '@/components/icon-library/agent-support.vue'
import CheckoutQuote from '@/components/checkout/quote.vue'
import DirectBillingInfo from '@/components/direct-billing-info/index.vue'

import IconBase from '@/components/icon-library/icon-base.vue'
import LoadingIcon from '@/components/loading-icon/index.vue'
import SummaryCard from '@/components/quote/summary-card.vue'

@Component({
  name: 'CheckoutConfirmation',
  components: {
    AgentSupport,
    CheckIcon,
    CheckoutQuote,
    DirectBillingInfo,
    IconBase,
    LoadingIcon,
    SummaryCard
  }
})
export default class CheckoutConfirmation extends Vue {
  get selectedQuote (): Quote | undefined {
    return quoteModule.quotesData.find(quote => quote.id === quoteModule.quoteSelections[0].quoteId)
  }

  get isBound () {
    return quoteModule.policyData?.status === PolicyStatus.Bound
  }

  get isSuccess () {
    return [PolicyStatus.Bound, PolicyStatus.ToBeBound].includes(quoteModule.policyData?.status as PolicyStatus)
  }

  get isDirectBilling () {
    return this.selectedQuote?.billingType === BillingType.Direct && !this.selectedQuote?.collectPayment
  }

  get quotePolicy (): Policy | null {
    return quoteModule.policyData
  }

  get quoteProducts (): Product[] {
    return quoteModule.productList.filter(product => product.id === quoteModule.quotesData[0].productId)
  }

  get quoteProductTypes (): ProductType[] {
    return quoteModule.productTypeList.filter(productType => productType.id === this.quoteProducts[0].productTypeId)
  }

  get productName (): string {
    return getProductName(this.quoteProductTypes[0].slug)
  }

  async created () {
    window.scrollTo(0, 0)
    document.title = brokerageModule.brokerage?.name
      ? `${brokerageModule.brokerage?.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }
}
