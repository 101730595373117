






















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SideContainer',
  props: {
    showCloseButton: {
      type: Boolean,
      default: true
    },
    containerClass: {
      type: String,
      required: false,
      default: 'hint-side_container'
    }
  },
  emits: ['close-drawer'],
  setup (props, { emit }) {
    const hide = () => {
      emit('close-drawer')
    }
    return { hide }
  }
})
