











import { Component, Vue, Prop } from 'vue-property-decorator'
import { Quote, InstallmentFrequency, PaymentOptions } from '@/models/quote'
@Component({
  name: 'MonthlyPayments'
})
export default class MonthlyPayments extends Vue {
  @Prop() quote!: Quote

  get installmentData (): PaymentOptions | undefined {
    return this.quote.paymentOptions.find(paymentOption =>
      paymentOption.installmentFrequency === InstallmentFrequency.Monthly)
  }

  get numberOfInstallments (): number {
    return this.installmentData ? this.installmentData.installmentNumberOfTimes : 0
  }

  get installmentPayment (): number {
    return this.installmentData ? this.installmentData.installmentPayment : 0
  }
}
