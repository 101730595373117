



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { renderMarkdown } from '@/helpers/markdown'

@Component({
  name: 'DirectBillingInfo'
})
export default class DirectBillingInfo extends Vue {
  @Prop({ required: true }) instructionsData !: string[]
  @Prop({ required: true }) policyReference !: string
  @Prop({ required: true }) quoteReference !: string
  @Prop({ required: true }) isBound !: boolean

  isHeader (instruction: string) {
    return typeof instruction === 'string' && instruction[0] === '#'
  }

  markDown (input: string, openLinksInNewWindow = false, linkClass?: string) {
    return renderMarkdown(input, openLinksInNewWindow, linkClass)
  }
}
