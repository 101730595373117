


























































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { CheckCircleIcon, InfoIcon } from 'vue-feather-icons'
import { Product } from '@/models/product'
import { Underwriter, BillingType } from '@/models/underwriter'
import { quoteModule, underwritersModule } from '@/store/store-accessor'
import DrawerDisplayMixin from '@/mixins/drawer-display-mixin'
import SideContainer from '@/components/side-container/index.vue'
import Hint from '@/components/hint/index.vue'
import { Quote, InstallmentFrequency } from '@/models/quote'
import MonthlyPayments from '@/components/quote/monthly-payments.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import LoadingIcon from '@/components/loading-icon/index.vue'
import BrokerageMixin from '@/mixins/brokerage-mixin'

@Component({
  name: 'SummaryCard',
  components: {
    Hint,
    SideContainer,
    InfoIcon,
    CheckCircleIcon,
    MonthlyPayments,
    LoadingIcon
  }
})
export default class SummaryCard extends Mixins(DrawerDisplayMixin, LoadableMixin, BrokerageMixin) {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly checkout!: boolean
  @Prop({ default: false }) readonly paid!: boolean

  hintCopyToDisplay = '';
  hintCopy = {
    MONTHLY_PAYMENT: 'This is the amount you’ll pay every monthly when the policy is bound.',
    DUE_ON_PURCHASE: 'Premium for this policy is collected in full at checkout.'
  };

  quoteToggleDisplayDrawer (hintCopy: string) {
    this.hintCopyToDisplay = hintCopy
    this.toggleDisplayDrawer()
  }

  get quoteSelections () {
    return quoteModule.quoteSelections
  }

  get monthlySelections () {
    return quoteModule.quotesData.reduce((acc: Quote[], curr) => {
      const isQuoteSelectedAtMontly = this.quoteSelections.find(quote =>
        quote.quoteId === curr.id && quote.installmentFrequency === InstallmentFrequency.Monthly)
      if (isQuoteSelectedAtMontly) {
        return [
          ...acc,
          curr
        ]
      }
      return [
        ...acc
      ]
    }, [])
  }

  // Currently looking at index [0] since we only support one product at the moment
  // should be expanded later for multiple products
  get selectedQuote () {
    return quoteModule.quotesData.find(quote => quote.id === this.quoteSelections[0].quoteId)
  }

  get quoteProduct (): Product | undefined {
    return quoteModule.productList.find(product => product.id === this.selectedQuote?.productId)
  }

  get underwriter (): Underwriter | undefined {
    return underwritersModule.underwriters.find(underwriter => underwriter.id === this.quoteProduct?.underwriterId)
  }

  get isDirectBilling () {
    return this.selectedQuote?.billingType === BillingType.Direct
  }

  get isAgencyBilling () {
    return this.selectedQuote?.billingType === BillingType.Brokerage
  }

  get collectPayment () {
    return this.selectedQuote?.collectPayment
  }

  get total (): number {
    if (!this.collectPayment) {
      return 0
    } else {
      // The cart total will the total down payment of all quotes selected
      return quoteModule.quoteSelections.reduce((downpaymentTotal, currentQuoteSelection) => {
        return downpaymentTotal + currentQuoteSelection.downpayment
      }, 0)
    }
  }

  proceedToCheckout () {
    this.showBtnLoading()
    this.$emit('proceed')
  }
}
