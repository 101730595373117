









import { Component, Prop, Vue } from 'vue-property-decorator'
import { Underwriter } from '@/models/underwriter'

@Component({
  name: 'UnderwriterLogo'
})
export default class UnderwriterLogo extends Vue {
  @Prop({ required: true }) underwriter!: Underwriter
  @Prop({ default: '100px' }) width!: string
  @Prop({ default: 'normal' }) size!: 'normal' | 'square'

  get logoPath () {
    if (this.size === 'square') {
      try {
        return require(`@/assets/logos/square/${this.underwriter.slug}.svg`)
      } catch (e: any) {
        this.$logger.info(`missing square logo version for ${this.underwriter.slug}`)
      }
    }
    return require(`@/assets/logos/${this.underwriter.slug}.png`)
  }
}
